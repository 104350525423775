<template>
  <div class="app-container">
    <div class="content-box">
      <div>
        <div class="choose-title">设备基本信息</div>
        <div class="choose-option">
          <el-row :gutter="20">
            <el-col :span="16" :xs="24"
              ><div class="grid-content bg-purple ugneg">
                <el-descriptions
                  class="margin-top"
                  :column="3"
                  direction="vertical"
                >
                 <el-descriptions-item label="当前状态实体卡ICCID">{{
                    basicTnformation.simIccid
                  }}</el-descriptions-item>
                  <el-descriptions-item label="虚拟卡/短号">
                  {{basicTnformation.virtualIccid}}/{{basicTnformation.shortNo}}
                  </el-descriptions-item>
                  <el-descriptions-item label="类型">{{
                    basicTnformation.type
                  }}</el-descriptions-item>
                  <el-descriptions-item label="模型">{{
                    basicTnformation.model
                  }}</el-descriptions-item>
                  <el-descriptions-item label="SN">{{
                    basicTnformation.sn
                  }}</el-descriptions-item>
                  <el-descriptions-item label="IMEI">{{
                    basicTnformation.imei
                  }}</el-descriptions-item>
                  <el-descriptions-item label="IMSI">{{
                    basicTnformation.imsi
                  }}</el-descriptions-item>
                  <el-descriptions-item label="MAC">{{
                    basicTnformation.mac
                  }}</el-descriptions-item>
                  <el-descriptions-item label="webui账号">{{
                    basicTnformation.webAccount
                  }}</el-descriptions-item>
                  <el-descriptions-item label="webui密码">{{
                    basicTnformation.webPassword
                  }}</el-descriptions-item>
                  <el-descriptions-item label="注册时间">{{
                    basicTnformation.createTime
                  }}</el-descriptions-item>
                  <el-descriptions-item label="最后连接时间">{{
                    basicTnformation.updateTime
                  }}</el-descriptions-item>
                  <el-descriptions-item label="运行时间">{{
                    basicTnformation.runTime
                  }}</el-descriptions-item>
                  <el-descriptions-item label="状态">{{
                    basicTnformation.status === "1" ? "在线" : "离线"
                  }}</el-descriptions-item>
                  <el-descriptions-item label="版本">{{
                    basicTnformation.softwareVersion
                  }}</el-descriptions-item>
                  <el-descriptions-item label="剩余电量">{{
                    basicTnformation.battery 
                  }}</el-descriptions-item>
                   <el-descriptions-item label="运营商">{{
                    basicTnformation.operator
                  }}</el-descriptions-item>
                </el-descriptions>
              </div></el-col
            >
            <el-col :span="8"
              ><div class="grid-content bg-purple">
                <div class="information-right">
                  <baidu-map
                    ak="3isqLYGym4Pz60BiOY4NZyLXVdxAMx6H"
                    class="map-two"
                    :center="mapCenter"
                    :zoom="mapZoom"
                    :scroll-wheel-zoom="true"
                    @ready="mapHandler"
                  >
                    <bm-navigation
                      anchor="BMAP_ANCHOR_TOP_LEFT"
                    ></bm-navigation>
                  </baidu-map>
                </div>
                <div>
                  <button class="map-two-button" @click="dialogBaiduMap = true">
                    编辑地址
                  </button>
                </div>
              </div></el-col
            >
          </el-row>
          <!-- <el-select v-model="value" placeholder="下拉菜单">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select> -->
        </div>
      </div>
      <!-- 2 -->
      <div>
        <div class="choose-title">网络信息</div>
        <div class="choose-option">
          <el-row :gutter="20">
            <el-col :span="20"
              ><div class="grid-content bg-purple ugnegOne">
                <el-descriptions
                  class="margin-top"
                  :column="6"
                  direction="vertical"
                >
                  <el-descriptions-item label="RSRP">{{
                    basicTnformation.rsrp
                  }}</el-descriptions-item>
                  <el-descriptions-item label="RSRQ">{{
                    basicTnformation.rsrq
                  }}</el-descriptions-item>
                  <el-descriptions-item label="SINR">{{
                    basicTnformation.sinr
                  }}</el-descriptions-item>
                  <el-descriptions-item label="RSSI">{{
                    basicTnformation.rssi
                  }}</el-descriptions-item>
                  <el-descriptions-item label="外网IP">{{
                    basicTnformation.wanIp
                  }}</el-descriptions-item>
                  <el-descriptions-item label="上行速率">{{
                    basicTnformation.uploadSpeed
                  }}</el-descriptions-item>
                  <el-descriptions-item label="下行速率">{{
                    basicTnformation.downloadSpeed
                  }}</el-descriptions-item>
                  <el-descriptions-item label="相对增量">{{
                    basicTnformation.uldlIncrement
                  }}</el-descriptions-item>
                  <el-descriptions-item label="日用量">{{
                    basicTnformation.dailyUsage
                  }}</el-descriptions-item>
                  <el-descriptions-item label="限速">{{
                    basicTnformation.limitSpeed
                  }}kbps</el-descriptions-item>
                </el-descriptions>
              </div></el-col
            >
          </el-row>
        </div>
      </div>
      <!-- 3 -->
      <div>
        <div class="choose-title">wifi信息</div>
        <div class="">
          <el-table
            ref="table"
            border
            stripe
            :data="wifiInformation"
            tooltip-effect="dark"
            style="width: 100%"
            :row-style="{ height: '45px' }"
            :cell-style="{ padding: '0px' }"
          >
            <el-table-column prop="ssid" label="WIFI名称" min-width="180">
            </el-table-column>
            <el-table-column prop="wifiType" label="WIFI类型" min-width="120">
               <template slot-scope="scope">
                <span>{{ scope.row.wifiType === "24G" ? "2.4G" : "5G" }}</span>
               </template>
            </el-table-column>
            <el-table-column prop="password" label="WIFI密码" min-width="180">
            </el-table-column>
            <el-table-column
              prop="wifiEnabled"
              label="是否启用"
              min-width="180"
            >
              <template slot-scope="scope">
                <el-tag
                  :type="scope.row.wifiEnabled == '1' ? 'success' : 'info'"
                  >{{ scope.row.wifiEnabled === "1" ? "启用" : "未启用" }}</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="broadcastStatus"
              label="是否隐藏"
              min-width="180"
            >
              <template slot-scope="scope">
                <el-tag
                  :type="
                    scope.row.broadcastStatus == '1' ? 'info' : 'success'
                  "
                  >{{
                    scope.row.broadcastStatus === "1" ? "未隐藏" : "隐藏"
                  }}</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="encryptType"
              label="加密类型"
              min-width="180"
            >
            </el-table-column>
            <el-table-column
              prop="maxClients"
              label="最大连接数"
              min-width="180"
            >
            </el-table-column>
            <el-table-column
              prop="clientNumber"
              label="当前连接数"
              min-width="180"
            >
            </el-table-column>
          </el-table>
          <!-- <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>  -->
        </div>
      </div>
    </div>

    <el-dialog title="地图" :visible.sync="dialogBaiduMap" width="60%">
      <div id="map-wrap" class="map-maker-wrapper">
        <baidu-map
          ak="3isqLYGym4Pz60BiOY4NZyLXVdxAMx6H"
          class="map-wrap"
          :center="mapCenter"
          :zoom="mapZoom"
          @click="getLocation"
          :scroll-wheel-zoom="true"
          @ready="mapHandlerOne"
        >
          <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation>
          <bm-geolocation
            anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
            :showAddressBar="true"
            :autoLocation="true"
          ></bm-geolocation>
        </baidu-map>
        <div class="search-wrap">
          <div class="search_box">
            <input
              class="search-input"
              type="text"
              v-model="keyword"
              @input="autoSearch"
            />
            <el-button
              class="search-button"
              size="small"
              type="info"
              plain
              icon="el-icon-search"
              @click="handleSearch"
            ></el-button>
          </div>

          <!-- 检索结果 -->
          <div v-show="showResultFlag" class="search-result">
            <div
              v-for="(item, index) in searchResult"
              class="item"
              :key="index"
              @click="handleSelect(item)"
            >
              <p class="title">{{ item.title }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="info">
        <span>详细地址</span>
        <el-input v-model="businessDetail.address"></el-input>
        <div v-if="!isChangeBusinessDetail">
          <span>经度: </span> {{ businessDetail.longitude }},
          <span>纬度: </span> {{ businessDetail.latitude }}
          <i
            class="el-icon-edit"
            @click="changeBusinessDetail"
            style="color: #1890ff; font-size: 16px; margin-left: 20px"
          ></i>
        </div>
        <div v-if="isChangeBusinessDetail">
          经度:
          <el-input
            v-model="businessDetail.longitude"
            class="input_box"
          ></el-input>
          纬度:
          <el-input
            v-model="businessDetail.latitude"
            class="input_box"
          ></el-input>
          <el-button size="small" type="info" @click="cancelBusinessDetail" class="themed-button"
            >取消</el-button
          > 
          <el-button size="small" type="primary" @click="sureBusinessDetail" class="themed-button"
            >保存</el-button
          >
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <!--  @click="dialogBaiduMap = false" -->
        <el-button size="small" type="primary" @click="submitForm" class="themed-button"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import {
  BmLocalSearch,
  BmNavigation,
  BmGeolocation,
  BmCityList,
} from "vue-baidu-map";

import { deviceMap } from "@/api/devices/devices.js";
const defaultInfo = {
  lng: 0,
  lat: 0,
  addressStr: "",
  title: "",
  province: "", // 省
  city: "", // 市
  district: "", // 区
};
export default {
  components: {
    BaiduMap,
    BmLocalSearch,
    BmNavigation,
    BmGeolocation,
    BmCityList,
  },
  //  props: {
  //     queryDataList: {
  //      type: Object,
  //      default:
  //     }
  // },
  props: ["queryDataList"],
  data() {
    return {
      basicTnformation: "",
      wifiInformation: [],
      tableData: [],
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        deviceId: this.deviceId,
        slaveId: "",
        variableName: "",
      },
      deviceInfo: {},
      slaves: [],
      BMap: null,
      map: null,
      mapCenter: {
        lng: "117.09449483",
        lat: "36.20585804",
      },
      mapZoom: 15,
      //缩放级别
      mapZoom: 13,
      //BMap类
      BMap: null,
      //地图对象
      map: null,
      //设备信息
      businessDetail: {
        address: "山东省泰安市泰山区望岳东路3号",
        longitude: "117.09449483", //经度
        latitude: "36.20585804", //纬度
      },
      businessDetail_copy: {
        longitude: "", //经度
        latitude: "", //纬度
      },
      isChangeBusinessDetail: false,
      keyword: "",
      searchResult: [], // 检索结果列表
      showResultFlag: true,
      selectInfo: Object.assign({}, defaultInfo),
      timer: null,
      dialogBaiduMap: false,
      cardList:[]
    };
  },
  watch: {
    queryDataList(n, o) {
      this.basicTnformation = n;
      this.cardList = this.basicTnformation.simList;
     this.businessDetail.address = this.basicTnformation.addr
     this.businessDetail.longitude = this.basicTnformation.lng
     this.businessDetail.latitude = this.basicTnformation.lat
     this.mapCenter.lng = this.basicTnformation.lng
     this.mapCenter.lat = this.basicTnformation.lat
      this.wifiInformation = this.basicTnformation.wifiList;
      this.basicTnformation.runTime= this.setSeconds(n.runTime)
      this.basicTnformation.uldlIncrement= this.sizeTostr(n.uldlIncrement)
      this.basicTnformation.dailyUsage= this.sizeTostr(n.dailyUsage)
      this.operatorCount();
    },
  },
  mounted() {
    this.basicTnformation = this.queryDataList;
    this.cardList = this.basicTnformation.simList;
     this.businessDetail.address = this.basicTnformation.addr
     this.businessDetail.longitude = this.basicTnformation.lng
     this.businessDetail.latitude = this.basicTnformation.lat
     this.mapCenter.lng = this.basicTnformation.lng
     this.mapCenter.lat = this.basicTnformation.lat
    this.wifiInformation = this.basicTnformation.wifiList;
    this.basicTnformation.runTime= this.setSeconds(this.queryDataList.runTime)
    this.basicTnformation.uldlIncrement= this.sizeTostr(this.queryDataList.uldlIncrement)
    this.basicTnformation.dailyUsage= this.sizeTostr(this.queryDataList.dailyUsage)
    this.operatorCount();
 },

  methods: {
    operatorCount() {
     if(this.cardList){
      this.cardList.forEach((constMot) => {
        let putt = constMot.simIccid.slice(4, 6);
        if (putt == "03" || putt == "11") {
          constMot.operator = "中国电信";
        } else if (putt == "06" || putt == "01" || putt == "09") {
          constMot.operator = "中国联通";
        } else if (putt == "15") {
          constMot.operator = "中国广电";
        } else {
          constMot.operator = "中国移动";
        }
     if (constMot.simId==this.basicTnformation.currentSimId) {
      this.basicTnformation.operator=constMot.operator
      this.basicTnformation.simIccid=constMot.simIccid
     }
      });
     }
     
    },
    //将秒数转换为天时分秒格式
    setSeconds(value) {
      var result = "";
      var sencond = parseInt(value);
      //3600秒等于60分钟等于1小时
      if (sencond > 3600 * 24) {
        //表示大于一天
        var day = sencond / (3600 * 24);
        sencond = sencond % (3600 * 24); //求天数整除外剩余的秒数
        var hour = sencond / 3600; //1小时==60分钟==3600秒，所以剩余的秒数÷3600= 得到几个小时数
        result = parseInt(day) + "天" + parseInt(hour) + "时";
      } else if (sencond > 3600) {
        //表示大于一个小时
        var hour = sencond / 3600;
        var minute = (sencond % 3600) / 60; //求小时数整除外剩余的秒数， 秒数÷60秒 = 得到几分钟
        result = parseInt(hour) + "时" + parseInt(minute) + "分";
      } else if (sencond > 60) {
        //表示大于一分钟
        var minute = sencond / 60;
        var sec = sencond % 60; //求分钟数整除外剩余的秒数
        result = parseInt(minute) + "分" + parseInt(sec) + "秒";
      } else {
        //不大于一天、不大于一个小时、也不大于一分钟，那就是秒数
        result = "" + parseInt(sencond) + "秒";
      }
      return result;
    },
    //将用量byte转换为mb kb等
    sizeTostr(size) {
    var data = "";
    if (size < 0.1 * 1024) { //如果小于0.1KB转化成B  
        data = size.toFixed(2) + "B";
    } else if (size < 0.1 * 1024 * 1024) {//如果小于0.1MB转化成KB  
        data = (size / 1024).toFixed(2) + "KB";
    } else if (size < 0.1 * 1024 * 1024 * 1024) { //如果小于0.1GB转化成MB  
        data = (size / (1024 * 1024)).toFixed(2) + "MB";
    } else { //其他转化成GB  
        data = (size / (1024 * 1024 * 1024)).toFixed(2) + "GB";
    }
    var sizestr = data + "";
    var len = sizestr.indexOf("\.");
    var dec = sizestr.substr(len + 1, 2);
    if (dec == "00") {//当小数点后为00时 去掉小数部分  
        return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
    }
    return sizestr;
} ,
    mapHandler({ BMap, map }) {
      this.BMap = BMap;
      this.map = map;
      map.clearOverlays(); //清除地图上所有覆盖物
      let lng = this.mapCenter.lng;
      let lat = this.mapCenter.lat;
      let point = new BMap.Point(lng, lat);
      map.addOverlay(new BMap.Marker(point));
    },
    mapHandlerOne({ BMap, map }) {
      this.BMap = BMap;
      this.map = map;
      map.clearOverlays(); //清除地图上所有覆盖物
      let lng = this.businessDetail.longitude;
      let lat = this.businessDetail.latitude;
      map.addOverlay(new BMap.Marker({ lng, lat }));
    },
    // getList() {
    //   deviceLatestData(this.queryParams).then((res) => {
    //     this.tableData = res.data.list;
    //     this.total = res.data.total;
    //   });
    // },
    parseValue(row) {
      if (!!row) {
        let value = row.value;
        let unit = row.unit;
        if (!!value) {
          if (!!unit) {
            return value + " " + unit;
          }
          return value;
        }
      }
      return "-";
    },
    handleQuery() {
      this.queryParams.pageNum = 1;
      //this.getList();
    },
    searchHistory(id) {
      var deviceName = this.$route.query.deviceName;
      this.$router.push({
        path: "/data/HistoryList",
        query: { data: JSON.stringify(id), deviceName, type: 2 },
      });
    },
    autoSearch() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.handleSearch();
      }, 1000);
    },
    cancelBusinessDetail() {
      this.businessDetail.longitude = this.businessDetail_copy.longitude;
      this.businessDetail.latitude = this.businessDetail_copy.latitude;
      this.isChangeBusinessDetail = false;
    },
    changeBusinessDetail() {
      this.businessDetail_copy.longitude = this.businessDetail.longitude;
      this.businessDetail_copy.latitude = this.businessDetail.latitude;
      this.isChangeBusinessDetail = true;
    },
    autoSearch() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.handleSearch();
      }, 1000);
    },
    handleSearch() {
      let self = this;
      self.showResultFlag = true;
      self.selectInfo = Object.assign({}, defaultInfo);
      let local = new this.BMap.LocalSearch(this.map, {
        renderOptions: {
          map: this.map,
          selectFirstResult: false,
        },
        onSearchComplete: function (res) {
          if (res && res.Kr) {
            self.searchResult = [...res.Kr];
          }
        },
      });
      local.search(this.keyword);
    },
    handleSelect(item) {
      let self = this;
      let title = item.title;
      let { lng, lat } = item.marker.point;
      let point = new this.BMap.Point(lng, lat);
      let geoc = new this.BMap.Geocoder();
      geoc.getLocation(point, function (res) {
        let addString =
          res.addressComponents.province +
          res.addressComponents.city +
          res.addressComponents.district +
          title;
        console.log("addString", addString);

        self.showResultFlag = false;
        self.keyword = addString;
        self.map.clearOverlays(); //清除地图上所有覆盖物
        self.map.addOverlay(new self.BMap.Marker({ lng, lat }));
        self.mapCenter.lng = lng;
        self.mapCenter.lat = lat;
        self.mapZoom = 15;
        self.selectInfo = {
          lng,
          lat,
          addressStr: addString,
          title: title,
          province: res.addressComponents.province,
          city: res.addressComponents.city,
          district: res.addressComponents.district,
        };
        self.businessDetail.address = addString;
        self.businessDetail.longitude = lng;
        self.businessDetail.latitude = lat;
      });
    },
    sureBusinessDetail(){
      if(isNaN(this.businessDetail.longitude) || this.businessDetail.longitude == ""){
        this.$alert('经度必须是数字', '提示', {
          confirmButtonText: '确定'
        });
        return;
      }
      if(isNaN(this.businessDetail.latitude) || this.businessDetail.latitude == ""){
        this.$alert('纬度必须是数字', '提示', {
          confirmButtonText: '确定'
        });
        return;
      }
      this.setLocation()
      this.isChangeBusinessDetail = false
    },
    setLocation() {
      let BMapGL = this.BMap;
      let map = this.map;
      map.clearOverlays();
      let point = new BMapGL.Point(
        this.businessDetail.longitude,
        this.businessDetail.latitude
      );
      let marker = new BMapGL.Marker(point);
      map.addOverlay(marker);
      let geoc = new BMapGL.Geocoder();
      geoc.getLocation(point, (rs) => {
        let addressComp = rs.addressComponents;
        this.businessDetail.address =
          addressComp.province +
          addressComp.city +
          addressComp.district +
          addressComp.street +
          addressComp.streetNumber;
      });
    },
    //在地图上选择区域
    getLocation(e) {
      //设置经度
      this.businessDetail.longitude = e.point.lng;
      //设置纬度
      this.businessDetail.latitude = e.point.lat;
      //百度地图类
      let BMapGL = this.BMap;
      //地图对象
      let map = this.map;
      //清除地图上所有的覆盖物(保证每次点击只有一个标记)
      map.clearOverlays();
      //创建定位标记
      let marker = new BMapGL.Marker(
        new BMapGL.Point(e.point.lng, e.point.lat)
      );
      //将标记添加到地图上
      map.addOverlay(marker);
      //创建坐标解析对象
      let geoc = new BMapGL.Geocoder();
      //解析当前的坐标成地址
      geoc.getLocation(e.point, (rs) => {
        //获取地址对象
        let addressComp = rs.addressComponents;
        //拼接出详细地址
        this.businessDetail.address =
          addressComp.province +
          addressComp.city +
          addressComp.district +
          addressComp.street +
          addressComp.streetNumber;
      });
    },
    // 修改地图信息
      submitForm: function() {
        this.$confirm("是否修改", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
        deviceMap(
        this.basicTnformation.id,
        this.businessDetail.address,
        this.businessDetail.latitude,
        this.businessDetail.longitude
       ).then((res=>{
       this.dialogBaiduMap=false
       //this.$router.push("equipmentManagement/taskList/index"); 
       }))
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

  },
};
</script>

<style lang="scss" scoped>
.box {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.box-card {
  border: none;
}
.big-box {
  margin-top: 15px;
}
.left-img {
  width: 100%;
  height: 18vh;
  margin: 20px;
  img {
    width: 100%;
    height: 100%;
  }
}

.information-right {
  width: 100%;
  height: 300px;
}
.map-two-button {
  margin-top: 20px;
  position: absolute;
  right: 1%;
}
::v-deep .ugneg .el-descriptions .el-descriptions__body {
  height: auto;
}
::v-deep
  .ugneg
  .el-descriptions--medium:not(.is-bordered)
  .el-descriptions-item__cell {
  padding-bottom: 0px;
  padding-left: 25px;
}
::v-deep .ugneg .has-colon {
  padding-top: 20px;
  font-weight: 600;
  // color: rgb(255, 151, 2);
}

::v-deep .ugnegOne .el-descriptions .el-descriptions__body {
  height: auto;
}
::v-deep
  .ugnegOne
  .el-descriptions--medium:not(.is-bordered)
  .el-descriptions-item__cell {
  padding-bottom: 0px;
  padding-left: 25px;
}
::v-deep .ugnegOne .has-colon {
  padding-top: 20px;
  font-weight: 600;
}
.map-two {
  width: 100%;
  height: 100%;
}

.title-mix {
  font-size: 14px;
  font-weight: 600;
}
.map-wrap {
  width: 100%;
  height: 400px;
}

.grid-content {
  min-height: 36px;
}

.info {
  line-height: 36px;
  .input_box {
    display: inline-block;
    width: 150px;
    margin-left: 10px;
    margin-top: 10px;
    margin-right: 10px;
  }
}
.search {
  width: calc(100% - 8px - 0.5rem);
  margin: 0 0.5rem;
}

.option-style {
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

.tree-click {
  color: #4d74d6;
  font-weight: 600;
}

.map-maker-wrapper {
  position: relative;
}

.search-wrap {
  position: absolute;
  top: 10px;
  right: 10px;

  .search_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search-button {
      width: 70px;
      height: 36px;
      font-size: 16px;
    }
    .search-input {
      width: 240px;
      border: none;
      font-size: 14px;
      color: #313233;
      background: #fff;
      height: 34px;
      line-height: 34px;
    }
  }
  .search-result {
    background-color: #fff;
    font-size: 14px;
    max-height: 320px;
    overflow: auto;
    width: 240px;
    .title {
      margin: 0;
      padding: 5px 10px;
      cursor: pointer;
    }
    .title:hover {
      background: #ddd;
    }
  }
}
.choose-title {
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid rgb(236, 231, 231);
  color: rgb(255, 151, 2);
}
.choose-option {
  margin-top: 30px;
}
.choose-imei {
  display: flex;
  margin-top: 30px;
  .choose-imei-title {
    margin-right: 100px;
  }
}
.choose-input {
  width: 600px;
}
.content-box {
  margin-left: 30px;
}
</style>