<template>
  <div class="materialsList fadeInRight content-box">
      <div class="title-box clearfix">
      <el-row :gutter="20">
        <el-col :span="4" :xs="24" :offset="20"
          ><div class="grid-content bg-purple">
            <el-form
              :inline="true"
              :model="formInline"
              class="demo-form-inline"
              style="height: auto; line-height: 40px; margin-top:5px"
            >
              <el-form-item>
                <el-button
                  icon="el-icon-plus"
                  type="primary"
                  class="themed-button"
                  @click="refresh"
                  >刷新</el-button
                >
              </el-form-item>
            </el-form>
          </div></el-col
        >
      </el-row>
    </div>
    <div >
      <el-table
        ref="table"
        border
        stripe
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        :row-style="{ height: '45px' }"
        :cell-style="{ padding: '0px' }"
      >
        <el-table-column prop="name" label="名称" min-width="150" :formatter="UniDecode">
        </el-table-column>
        <el-table-column prop="mac" label="MAC" min-width="150">
        </el-table-column>
        <el-table-column    prop="ip" label="IP" min-width="120">
        </el-table-column>
        <el-table-column prop="status" label="状态" min-width="120">
           <template slot-scope="scope">
          <el-tag :type="scope.row.status == 'conn' ? 'success' : 'danger'">{{ scope.row.status === "conn" ? "连接" : scope.row.status === "black" ? "黑名单":"未连接"  }}</el-tag>
        </template>
        </el-table-column>
        <el-table-column label="操作" min-width="180">
          <template slot-scope="scope">
            <el-button v-show="scope.row.status=='conn'"
              type="danger"
              class="themed-button"
              size="mini"
              @click="getBlack(scope.row)"
              >加入黑名单</el-button
            >
            <el-button  v-show="scope.row.status=='conn'"
              type="danger" 
              class="themed-button"
              size="mini"
              @click="getDisconn(scope.row)"
              >剔除用户</el-button
            >
             <el-button  v-show="scope.row.status=='black'"
              type="danger" 
              class="themed-button"
              size="mini"
              @click="getDisblack(scope.row)"
              >解除黑名单</el-button
            >
          </template>
        </el-table-column>
      </el-table>
         <!-- <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination> -->
    </div>
  </div>
</template>

<script>
import {
  syncList,deviceUserOperation 
} from "@/api/devices/devices.js";
export default {
  props: ["queryDataList"],
  data() {
    return {
     formInline:{
      spec:"",
      material:"",
     },
     loading: false,
      tableData: [],
      materialList:[],
      specList:[],
      page: 1,
      pageSize: 15,
      total: 0,
      deviceCount:""
    };
  },
  watch: {
    queryDataList(n, o) {
      console.log(n,2323)
      //this.tableData = n.userList;
      this.deviceCount= n.id
      this.refresh()
    },
  },
  created() {
   //this.tableData = this.queryDataList.userList;
   this.deviceCount=this.queryDataList.id
  },
  methods: {
      // unicode转换
     UniDecode(row) {
      var encodeString = row.name
    if (undefined == encodeString) {
        return "";
    }
    var deCodeStr = "";

    var strLen = encodeString.length / 4;
    for (var idx = 0; idx < strLen; ++idx) {
        deCodeStr += String.fromCharCode(parseInt(encodeString.substr(idx * 4, 4), 16));
    }
    return deCodeStr;
},
UniEncode(string) {
    if (undefined == string) {
        return "";
    }
    var code = "";
    for (var i = 0; i < string.length; ++i) {
        var charCode = string.charCodeAt(i).toString(16);
        var paddingLen = 4 - charCode.length;
        for (var j = 0; j < paddingLen; ++j) {
            charCode = "0" + charCode;
        }

        code += charCode;
    }
    return code;
},
// ---------------------------
    // 刷新
    refresh(){
      let cd = {
      deviceId: this.deviceCount,
      };
    this.loading = true;
    syncList(cd).then((res)=>{
      console.log(res,888)
        this.tableData=res
        this.loading = false;
        })
         setTimeout(() => {
          this.loading = false;
        }, 30000);
      
    },
    // 添加黑名单
    getBlack(row){
      let form = new FormData();
      form.append("createBy", row.createBy);
      form.append("createTime", row.createTime);
      form.append("updateBy", row.updateBy);
      form.append("ip", row.ip);
      form.append("name", row.name);
      form.append("updateTime", row.updateTime);
      form.append("remark", row.remark);
      form.append("id", row.id);
      form.append("delFlag", row.delFlag);
      form.append("deviceId", row.deviceId);
      form.append("mac", row.mac);
      form.append("status",row.status)
       form.append("statusCode","black")
      deviceUserOperation(form).then((res=>{
      this.refresh()
     }))
    },
    // 剔除用户dis_conn
     getDisconn(row){
      let form = new FormData();
      form.append("createBy", row.createBy);
      form.append("createTime", row.createTime);
      form.append("updateBy", row.updateBy);
      form.append("ip", row.ip);
      form.append("name", row.name);
      form.append("updateTime", row.updateTime);
      form.append("remark", row.remark);
      form.append("id", row.id);
      form.append("delFlag", row.delFlag);
      form.append("deviceId", row.deviceId);
      form.append("mac", row.mac);
      form.append("status",row.status)
      form.append("statusCode","dis_conn")
    deviceUserOperation(form).then((res=>{
    this.refresh()
     }))

     },
     // 解除黑名单conn
    getDisblack(row){
      let form = new FormData();
      form.append("createBy", row.createBy);
      form.append("createTime", row.createTime);
      form.append("updateBy", row.updateBy);
      form.append("ip", row.ip);
      form.append("name", row.name);
      form.append("updateTime", row.updateTime);
      form.append("remark", row.remark);
      form.append("id", row.id);
      form.append("delFlag", row.delFlag);
      form.append("deviceId", row.deviceId);
      form.append("mac", row.mac);
      form.append("status",row.status)
      form.append("statusCode","conn")
    deviceUserOperation(form).then((res=>{
    this.refresh()
     }))

     },
      onPageChange(page) {
      this.page = page;
      this.syncList()
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.syncList()
    },
   
    // getMaterialsList() {
    //     let params = {
    //     pageSize: this.pageSize,
    //     pageNo: this.page,
    //   };
    //   if(this.formInline.spec!==""){
    //    params.spec=this.formInline.spec
    //   }
    //   if(this.formInline.material!==""){
    //    params.material=this.formInline.material
    //   }
     
    //   getFeepageList(params).then((res) => {
    //     this.tableData = res.rows
    //     this.total = res.count;
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
.content-box{
  margin-left: 40px;
  margin-top: 30px;
}
.m-title {
	color: #676a6c;
	font-weight: 700;
	font-size: 14px;
  height: 60px;
  line-height: 60px;
  padding-left: 50px;
  border-bottom:1px solid #d1d4d6;
}
.materialsFee .el-form-item__content{width: 206px;
    display: inline-block;}
.materialsList .el-form-item__label {vertical-align: top;}
</style>