<template>
  <div class="app-container">
   <div class="content-boxcoss">
     <!--重启-->
      <div class="partial_boxcoss">
      <div class="partial_title">重启</div>
      <div class="partial_content" style="padding-left:20px">
      <el-button type="primary" @click="getReboot" style="background:#f06400;border-color: #f06400; " size="mini">重启</el-button>
      </div>
     </div>
     <!--  -->
     <!--恢复出厂设置-->
      <div class="partial_box">
      <div class="partial_title">恢复出厂设置</div>
      <div class="partial_content" style="padding-left:20px">
       <el-button type="primary" @click="getRestore" style="background:#f06400;border-color: #f06400;" size="mini">恢复出厂</el-button>
      </div>
     </div>
     <!--  -->
      <!-- 限速 -->
     <div class="partial_box">
      <div class="partial_title">限速</div>
      <div class="partial_content">
        <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="50px"
      class="demo-ruleForm"
    >
      <div class="nowLimitSpeed"  style="color:#f06400;">
      <span>当前限速值:</span>
      <span>{{limitSpeedOne}}</span>
      </div>
      <el-form-item label="操作" prop="resource">
        <el-radio-group v-model="ruleForm.resource">
          <el-radio :label="0">限速</el-radio>
          <el-radio :label="1" v-show="limitSpeedOne!=0">解除</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="限速" prop="speedLimitOne" v-show="ruleForm.resource == 0">
        <el-input v-model="ruleForm.speedLimitOne" style="width:200px; margin-right:10px" ></el-input>
        <span>kbps</span>
      </el-form-item>
 

    <el-form-item>
      <el-button type="primary" @click="getLimit" size="mini" style="background:#f06400;border-color: #f06400;" v-show="ruleForm.resource == 0">保存</el-button>
      <el-button @click="secure" size="mini"  v-show="limitSpeedOne!=0">解除限速</el-button>
    </el-form-item>
   </el-form>
      </div>
     </div>
     <!--  -->
      <!--切卡-->
      <div class="partial_box">
      <div class="partial_title">切卡</div>
      <div class="partial_contentOne">
        <div v-show="cardList && cardList.length != 0" class="content-boxOne">
        <div
          class="conter-boder"
          :style="{
            opacity: item.simId == cardListOne.currentSimId ? '1' : '.3',
          }"
          v-for="(item, index) in cardList"
          :key="index"
          @click="getSwitchCard(item)"
        >
          <!-- :style="{'opacity':item.simPresent=== '1'?'.5':'1'}" -->
          <!-- <div class="card_information"> -->
          <!-- <svg-icon icon-class="telecom" class="card_unicom" style="height: 50px;width: 40px;"/> -->
          <!-- <svg-icon icon-class="unicom" class="card_unicom" style="height: 40px;width: 40px;"/> -->
          <!-- <svg-icon icon-class="mobile" class="card_unicom" style="height: 40px;width: 40px;"/> -->
          <p
            style="
              color: red;
              font-weight: 600;
              font-size: 16px;
              text-align: center;
            "
          >
            {{ item.simName }}
          </p>
          <p>运营商:{{ item.operator }}</p>
          <!-- </div> -->
          <p>{{ item.simType === "1" ? "贴片卡" : "插拔卡" }}</p>
          <p>ICCID:{{ item.simIccid }}</p>
          <p style="color: green; font-weight: 600">
            {{ item.simPresent === "1" ? "在位" : "不在位" }}
          </p>
        </div>
      </div>
      <div class="conter-boder Intelligent_box" @click="getIntelligentd" style="opacity: 0.3">
        <p class="Intelligent" style="
              color: coral;
              font-weight: 600;
              font-size: 16px;
            ">智能选网</p>
      </div>
      </div>
     </div>
     <!--  -->
   </div>
  </div>
</template>
<script>
import { operationRateLimit,operationReboot,operationRestore,operationSwitchCard } from "@/api/devices/devices.js";
export default {
  props: ["queryDataList"],
  data() {
    return {
      cardListOne: "",
      cardList: [],
      rebootOne: "",
      restoreOne: "",
      ruleForm: {
      resource: 0,
      speedLimitOne:"",
      deviceId:""
      },
      speedLimitCon: "",
      limitSpeedOne:"",
        rules: {
        speedLimitOne: [
          { required: true, message: "请输入限速值", trigger: "blur" },
          { pattern:/^\d+$/ , message: "限速值只能为数字", trigger: "blur" },
        ],
        resource: [
            { required: true, message: '请选择操作', trigger: 'change' }
          ],
      }
    };
  },
  watch: {
    queryDataList(n, o) {
      this.speedLimitCon = n;
      this.limitSpeedOne=this.speedLimitCon.limitSpeed
      this.rebootOne = n;
       this.restoreOne = n;
      this.cardListOne = n;
      this.cardList = this.cardListOne.simList;
      this.operatorCount();
    },
  },
  created() {
    this.speedLimitCon = this.queryDataList;
    this.limitSpeedOne=this.speedLimitCon.limitSpeed
    console.log(this.limitSpeedOne,12121212)
    this.rebootOne = this.queryDataList;
    this.restoreOne = this.queryDataList;
    this.cardListOne = this.queryDataList;
    this.cardList = this.cardListOne.simList;
    this.operatorCount();
  },
  methods: {
    // 限速
    getLimit() {
        this.$refs["ruleForm"].validate(valid => {
        this.ruleForm.deviceId=this.speedLimitCon.id
        if (valid) {
          let paramValue = new FormData();
          paramValue.append("deviceId", this.ruleForm.deviceId);
          paramValue.append("resource", this.ruleForm.resource);
          paramValue.append("speedLimit", this.ruleForm.speedLimitOne);
          operationRateLimit(paramValue).then((res) => {
        if (res.code == "0") {
          this.$message({
            message: "提交请求成功",
            type: "success",
          });
          this.$router.push("/equipmentManagement/taskList");
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      }); 
        }
        })
    },
     secure() {
        this.ruleForm.speedLimitOne="0"
        this.$refs["ruleForm"].validate(valid => {
        this.ruleForm.deviceId=this.speedLimitCon.id
        if (valid) {
          let paramValue = new FormData();
          paramValue.append("deviceId", this.ruleForm.deviceId);
          paramValue.append("resource", this.ruleForm.resource);
          paramValue.append("speedLimit", this.ruleForm.speedLimitOne);
          operationRateLimit(paramValue).then((res) => {
        if (res.code == "0") {
          this.$message({
            message: "提交请求成功",
            type: "success",
          });
          this.$router.push("/equipmentManagement/taskList");
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      }); 
        }
        })
    },
    // 重启
     getReboot() {
      this.$confirm("是否重启", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let paramValue = new FormData();
          paramValue.append("deviceId", this.rebootOne.id);
          operationReboot(paramValue).then((res) => {
            console.log(res,122121)
            if (res.code == "0") {
              this.$message({
                message: "提交请求成功",
                type: "success",
              });
              this.$router.push("/equipmentManagement/taskList");
            } else {
              this.$message({
                message: res.msg,
                type: "error",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 恢复出厂
    getRestore() {
      this.$confirm("是否恢复出厂设置", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let paramValue = new FormData();
          paramValue.append("deviceId", this.restoreOne.id);
          operationRestore(paramValue).then((res) => {
            if (res.code == "0") {
              this.$message({
                message: "提交请求成功",
                type: "success",
              });
              this.$router.push("/equipmentManagement/taskList");
            } else {
              this.$message({
                message: res.msg,
                type: "error",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 切卡
    operatorCount() {
     if(this.cardList){
      this.cardList.forEach((constMot) => {
        let putt = constMot.simIccid.slice(4, 6);
        if (putt == "03" || putt == "11") {
          constMot.operator = "中国电信";
        } else if (putt == "06" || putt == "01" || putt == "09") {
          constMot.operator = "中国联通";
        } else if (putt == "15") {
          constMot.operator = "中国广电";
        } else {
          constMot.operator = "中国移动";
        }
      });
     }
    },
    getIntelligentd() {
      this.$confirm("是否进行切换操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let paramValueOne = new FormData();
          paramValueOne.append("deviceId", this.cardListOne.id);
          paramValueOne.append("mode", 0);
          operationSwitchCard(paramValueOne).then((res) => {
            if (res.code == "0") {
              this.$message({
                message: "提交请求成功",
                type: "success",
              });
              this.$router.push("/equipmentManagement/taskList");
            } else {
              this.$message({
                message: res.msg,
                type: "error",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    getSwitchCard(item) {
      console.log(item, 11);
      this.$confirm("是否进行切换操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let paramValue = new FormData();
          paramValue.append("deviceId", this.cardListOne.id);
          paramValue.append("simId", item.simId);
          paramValue.append("mode", 1);
          operationSwitchCard(paramValue).then((res) => {
            if (res.code == "0") {
              this.$message({
                message: "提交请求成功",
                type: "success",
              });
              this.$router.push("/equipmentManagement/taskList");
            } else {
              this.$message({
                message: res.msg,
                type: "error",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.content-boxcoss{
margin-top: 10px;
margin-left: 30px;
height: 100vh;
}

.partial_box{
margin: 20px 0px;
padding-left: 20px;
background: #fff;
}
.partial_boxcoss{
margin-bottom: 20px;
padding-left: 20px;
background: #fff;
}
.partial_title{
padding-top: 20px;
 font-size: 14px; 
 font-weight: 600;
 margin-bottom: 30px;
}
.partial_content{
 padding-bottom: 20px; 
}
.partial_contentOne{
 padding-bottom: 20px; 
  display: flex;
}
.nowLimitSpeed{
margin:30px 10px;
span{
font-size:14px;  
}
}
.content-boxOne {
  display: flex;
}
.conter-boder {
  min-width: 200px;
  border: 2px solid rgb(182, 182, 230);
  text-align: center;
  padding-top: 10px;
  margin: 0 20px;
  p {
    font-size: 12px;
    height: 30px;
    line-height: 30px;
  }
}
.card_information {
  display: flex;
  .card_unicom {
    margin: 0 20px;
  }
}
.Intelligent_box{
position: relative;  
}
.Intelligent {
position: absolute;
top: 35%;
left: 33%;
}
</style>